<template>
  <div>
    <Top></Top>
    <div class="web_box">
      <div class="title">用户注册</div>
      <el-form
        :model="sysUser"
        :rules="rules"
        ref="sysUser"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!--<el-form-item label="注册类型" prop="rid">
          <el-radio-group v-model="sysUser.rid" v-for="item in registerType" :key="item.code" @change="toggleTab(item.code)">
            <el-radio :label="item.value" style="margin-left:15px"></el-radio>
          </el-radio-group> 
          <el-radio-group v-model="sysUser.rid">
            <el-radio label="11" @change="toggleTab($event)">社会个人用户</el-radio>
            <el-radio label="12" @change="toggleTab($event)">公众查询用户</el-radio>   
          </el-radio-group>
        </el-form-item>-->
        <el-form-item label="姓名" prop="fullname">
          <el-input
            v-model="sysUser.fullname"
            autocomplete="off"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input
            v-model="sysUser.phone"
            autocomplete="off"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="短信验证码" prop="code">
          <div class="text" @click="showPictureCode" v-show="show">
            获取验证码
          </div>
          <div class="text" v-show="!show">再次获取({{ count }}s)</div>
          <el-input
            v-model="sysUser.code"
            autocomplete="off"
            placeholder="请输入验证码"
          ></el-input>
          <div class="error" v-show="phoneVisable">{{ phoneMessage }}</div>
        </el-form-item>
        <el-form-item label=" 密码" prop="password">
          <el-input type="password"
            v-model="sysUser.password"
            autocomplete="off"
            placeholder="请输入8-16位数字、字母、特殊字符的组合"
          ></el-input>
        </el-form-item>
        <el-form-item label=" 确认密码" prop="confirm_password">
          <el-input type="password"
            v-model="sysUser.confirm_password"
            autocomplete="off"
            placeholder="请输入确认密码"
          ></el-input>
        </el-form-item>
        <!--
        <el-form-item label=" 人员类型" prop="personType" v-show="personTypeVisable">
          <el-select
            v-model="sysUser.personType"
            placeholder="请选择人员类型"
          >
            <el-option
              v-for="item in personType"
              :key="item.VALUE"
              :label="item.LABEL"
              :value="item.VALUE"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" 地区" prop="region_county" class="mechanism" v-show="personTypeVisable">
          <el-select
            v-model="sysUser.region_province"
            placeholder="请选择地区"
            class="mechanism"
            @change="getCity()"
          >
            <el-option
              v-for="item in province"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="sysUser.region_city"
            placeholder="请选择地区"
            class="mechanism"
            @change="getDistrict()"
          >
            <el-option
              v-for="item in city"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="sysUser.region_county"
            placeholder="请选择地区"
            class="mechanism"
          >
            <el-option
              v-for="item in district"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>-->
      </el-form>

      <div class="btn" @click="submitForm('sysUser')">立即注册</div>
      <div class="miaoshu">
        <div><span>已拥有账号，</span><span @click="goLogin()">立即登录</span></div>
      </div>
    </div>
      <PuzzleCode
        :bind="$attrs"
        :show="showVcode"
        success-text="验证成功"
        fail-text="验证失败"
        slider-text="拖动滑块完成拼图"
        @success="success"
        @close="close"
      />
      <!-- <div class="footer">
          <img src="../../assets/footer.png" alt="">
        </div> -->
  </div>
</template>
<script>
export function isvalidPhone(str) {
  const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
  return reg.test(str);
}
export function isvalidPassword(str) {
  const reg = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$)^.{8,16}$/;
  return reg.test(str);
}

import Top from "../../components/top/index.vue";
import PuzzleCode from '@/components/PuzzleCode';

export default {
  components: {
    Top,PuzzleCode
  },
  data() {
    //定义一个全局的变量(姓名)
    var validName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入姓名"));
      } else if ( value.length > 32 || value.length < 2) {
        callback(new Error("姓名长度为2-32个字"));
      } else {
        callback();
      }
    };
    //定义一个全局的变量(电话号码)
    var validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号码"));
      } else if (!isvalidPhone(value)) {
        callback(new Error("手机号码格式不正确"));
      } else {
        this.$ajax({
          method: "post",
          url: "/userInfo/checkPhoneNumber",
          params: {
            phone: value
          }
        })
        .then(function (res) {
          if(res.data == 0){
            callback();
          }else{
            callback(new Error('手机号已存在，请直接登录'))
          }
        })
        .catch(function (error) {
          console.log(error)
        })
      }
    };
    //定义一个全局的变量(密码)
    var validPassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      } else if (!isvalidPassword(value)) {
        callback(new Error("请输入8-16位数字、字母、特殊字符的组合的密码"));
      } else {
        callback();
      }
    }; 
    //定义一个全局的变量(确认密码)
    var validConfirmPassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.sysUser.password) {
        callback(new Error("两次输入的密码不一致，请检查"));
      } else {
        callback();
      }
    };     
    //定义一个全局的变量(验证码)
    var validSmsCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入验证码"));
      } else {
        this.$http({
          method: "post",
          url: "/code/valid",
          params: {
            smsCode: this.sysUser.code,
            mobile: this.sysUser.phone,
          },
        })
        .then((res) => {
          if (res.data === null) {
            callback();
          } else {
            callback(new Error(res.data));
          }
        })
        .catch((res) => {
          console.log(res);
        });
      };
    };
    return {
      sysUser: {
        name: "", //姓名
        rid : '12',
        personType: '',
        phone: "", //手机号
        code: "", //验证码
        password: "", //密码
        confirm_password: "", //确认密码
        personnel: "", //人员类型
        mechanism: "", //机构类型
        mechanism_name: "", //机构名称
        mechanism_code: "", //机构代码
        region: "", //地区
        region_province: "", //省
        region_city: "", //市
        region_county: "", //县
        region_village: "", //村
      },
      rules: {
        rid: [{ required: true, message: "请选择注册类型", trigger: "blur" }],
        fullname: [{ required: true, validator: validName, trigger: "blur" }],
        phone: [{ required: true, validator: validPhone, trigger: "blur" }],
        code: [{ required: true, validator: validSmsCode, trigger: "blur" }],
        password: [{ required: true, validator: validPassword, trigger: "blur" }],
        confirm_password: [{ required: true, validator:validConfirmPassword, trigger: "blur" }],
      },
      registerType:[],
      personType:[],
      province:[],
      city:[],
      district:[],
      personTypeVisable:true,
      showVcode:false,

      //验证码倒计时
      show: true,
      count: "",
      timer: null,
      phoneCheck: false,
      phoneVisable:false,
      phoneMessage: "",
    };
  },
  created(){
    this.getRegisterType()
    this.getProvince()
    this.getPersonType()
  },
  methods: {
    showPictureCode(){
      this.checkPhoneNumber();
      if (this.phoneCheck === true) {
        this.showVcode = true;
      }
    },
    success() {
      this.showVcode = false;
      this.getCode()
    },
    close() {
      this.showVcode = false
    },
    //校验手机号
    checkPhoneNumber() {
      var value = this.sysUser.phone;
      if (!value) {
        this.phoneVisable = true;
        this.phoneMessage = "请输入手机号码再获取验证码";
        this.phoneCheck = false;
      } else if (!isvalidPhone(value)) {
        this.phoneVisable = true;
        this.phoneMessage = "手机号码格式不正确";
        this.phoneCheck = false;
      } else {
        this.phoneVisable = false;
        this.phoneMessage = "";
        this.phoneCheck = true;
      }
    },
    //生成验证码
    getCode(formName) {
      this.$http({
        method: "get",
        url: "/code/sms",
        params: {
          mobile: this.sysUser.phone,
          type: "11"
        },
      })
      .then((res) => {
        this.$message({
          showClose: true,
          type: 'info',
          message: res.data
        });
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      })
      .catch((res) => {
        console.log(res);
      });
    },
    toggleTab(a){
      if(a === '11'){
        this.personTypeVisable = true
        this.rules.personType = [{ required: true, message: "请选择人员类型", trigger: "blur" }]
        this.rules.region_county = [{ required: true, message: "请选择地区", trigger: "blur" }]
      }
      if(a === '12'){
        this.personTypeVisable = false
        this.rules.personType = [{ required: false }]
        this.rules.region_county = [{ required: false }]
      }
    },
    //获取人员类型
    getRegisterType(){
      this.$ajax.post('/userInfo/getRegisterType')
        .then(res=>{
          this.registerType = res.data
        }).catch(res=>{
          console.log(res)
        }); 
    },
    //获取人员类型
    getPersonType(){
      this.$ajax.post('/userInfo/getPersonType')
        .then(res=>{
          console.log(res)
          this.personType = res.data
        }).catch(res=>{
        }); 
    },
    //获取1级地区
    getProvince(){
        this.$ajax({
          method: "post",
          url: "/userInfo/getProvince",
        })
        .then(res=>{
          
          this.province = res.data
          this.sysUser.region_city = ''
          this.sysUser.region_county = ''
        }).catch(res=>{
          
        }); 
    },
    //获取2级地区
    getCity(){
      this.$ajax({
        method: "post",
        url: "/userInfo/getCity",
        params: {
          supercode: this.sysUser.region_province
        }
      })
      .then(res=>{
        this.city = res.data
        this.sysUser.region_city = ''
        this.sysUser.region_county = ''
      }).catch(res=>{
        
      }); 
    },
    //获取3级地区
    getDistrict(){
      this.$ajax({
        method: "post",
        url: "/userInfo/getDistrict",
        params: {
          supercode: this.sysUser.region_city
        }
      })
      .then(res=>{
        this.district = res.data
        this.sysUser.region_county = ''
      }).catch(res=>{
        console.log(res)
      }); 
    },
    //提交表单
    submitForm(formName){
      if(this.sysUser.region_county!==''){
        this.sysUser.area = this.sysUser.region_county
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$ajax
            .post("/userInfo/saveRegister", this.sysUser)
            .then(res => {
              //弹窗提示
              this.$alert("注册成功，请登录", "提示", {
                confirmButtonText: "确定",
                callback: action => {
                  this.$router.push('/');
                }
              });
            })
            .catch(res => {
              //弹窗提示
              this.$alert("操作失败", "提示", {
                confirmButtonText: "确定",
                callback: action => {
                  //todo
                }
              });
            });
        }
      });
    },
    goLogin(){
      this.$router.push('/')
    }
  },
};
</script>
<style scoped>
.title {
  font-size: 24px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #3275d6;
  text-align: center;
  margin-top: 47px;
  margin-bottom: 50px;
}
.web_box {
  width: 1200px;
  margin: auto;
}
::v-deep .el-form {
  padding-left: 200px;
}
::v-deep .el-input__inner {
  width: 404px;
  height: 48px;
  padding-left: 30px;
}
::v-deep .el-form-item {
  margin-bottom: 22px;
  display: flex;
  /* justify-content: center; */
}
::v-deep .el-form-item:last-child {
}

::v-deep .el-form-item__content {
  margin-left: 20px !important;
}
::v-deep .el-input__inner {
  border-radius: 24px;
}
::v-deep .el-form-item__label {
  width: 140px !important;
}
::v-deep .mechanism {
  margin-right: 20px;
}
::v-deep .mechanism > .el-input .el-input__inner {
  width: 170px;
}
.btn {
  width: 404px;
  height: 48px;
  background: #3275d6;
  border-radius: 24px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
   cursor:pointer;
}
.miaoshu {
  text-align: right;
  width: 400px;
  margin: auto;
  margin-top: 30px;
  font-size: 16px;
font-family: MicrosoftYaHei;
color: #333333;
}
.miaoshu span:nth-child(2){
  color: #3275D6 ;
  border-bottom: 1px solid #3275D6;
   cursor:pointer;

}
.text{
  font-size: 16px;
font-family: MicrosoftYaHei;
color: #3275D6;
position: absolute;
top: 5px;
right: 30px;
z-index: 99;
cursor:pointer; 
}
::v-deep .el-input{
    font-size: 16px;
}
.footer img{
  width: 100%;
}
.error {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}

</style>